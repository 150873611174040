@use '_variables' as v;

:root {
  --Navy: #271d5a;
}

#root {
  height: 100%;
  width: 100%;
}

h1 {
  color: #271d5a;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  margin: 0px;
}

h5 {
  color: #000;
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  margin: 0px;
}

h4 {
  color: #271d5a;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: -0.14px;
  margin: 0px;
}

input[type|='submit'] {
  border-width: 0px;
}

.button {
  background: linear-gradient(
    0deg,
    v.$primary-gradient-step2-color 0%,
    v.$primary-gradient-step1-color 25.53%,
    v.$primary-color 100%
  );
  border-radius: 4px;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  padding: 8px 16px 8px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  margin-right: 4px;
  &:active:hover {
    background: linear-gradient(
      0deg,
      v.$primary-color 0%,
      v.$primary-gradient-step1-color 25.53%,
      v.$primary-gradient-step2-color 100%
    );
  }
  &.secondary {
    background: linear-gradient(
      0deg,
      v.$secondary-color 0%,
      v.$secondary-gradient-step1-color 25.53%,
      v.$secondary-color 100%
    );
    &:active:hover {
      background: linear-gradient(
        0deg,
        v.$secondary-color 0%,
        v.$secondary-gradient-step1-color 25.53%,
        v.$secondary-gradient-step2-color 100%
      );
    }
  }
  &.no-fill {
    color: v.$secondary-font-color;
    background-color: transparent;
    background: transparent !important;
  }
  &.solid {
    background: v.$primary-color;
  }
  &.hidden {
    display: none;
  }
}
.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.warning {
  background-color: #e1eef4;
  border: 1px solid #2989af;
  border-radius: 4px;
  font-size: 14px;
  margin-right: 15%;
  margin-left: 15%;
  padding: 12px;
  display: flex;
  align-items: center;
}

.loading {
  position: sticky;
  top: 50%;
  left: 50%;
  z-index: 1000;
  font-size: 32px;
}

.App {
  text-align: center;
  font-family: v.$font-stack;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: v.$background-color;
}

.App-router {
  display: contents;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: loader-spin infinite 20s linear;
  }
  .loader {
    animation: loader-spin infinite 5s linear;
  }
}

.container-base {
  display: flex;
  background-color: v.$background-color;
}

.h-full {
  height: 100%;
}

.App-header {
  background-color: v.$primary-color;
  height: 64px;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: v.$background-color;
  padding-left: 24px;
  padding-right: 24px;
  flex-shrink: 0;
  .header-group {
    display: flex;
    align-items: center;
  }
}

.App-body {
  background-color: v.$background-color;
  flex-grow: 1;
  font-size: calc(10px + 2vmin);
  color: v.$primary-font-color;
  overflow-y: auto;
}

.App-link {
  color: #61dafb;
}

.App-module {
  color: v.$primary-font-color;
  display: flex;
  flex-direction: column;
  padding: 40px;
}

.App-submodule {
  flex-grow: 1;
  overflow-y: auto;
}

h3 {
  font-size: 16px;
  color: #212529;
  font-weight: normal;
}

h2 {
  font-size: 18px;
  &.divider {
    text-align: center;
    border-bottom: 2px solid #d8d8d8;
    line-height: 0.1em;
    margin: 10px 0 20px;
  }
  span {
    background: v.$background-color;
    padding: 0 10px;
  }
}

label {
  font-size: 13px;
  text-align: center;
  margin: 5px;
}

.selector-wrapper {
  display: flex;
  height: 300px;
  flex-grow: 1;
}
@keyframes loader-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.touched {
  color: v.$secondary-font-color;
  font-style: italic;
  font-weight: bold;
}

.container {
  display: flex;
  padding: 16px;
  flex-wrap: wrap;
  &.header-row {
    flex-direction: row;
    align-items: center;
    column-gap: 125px;
    row-gap: 10px;
  }
  &.no-pad {
    padding: 0px;
  }
  &.col {
    flex-direction: column;
    flex-grow: 1;
    font-size: 14px;
    &.spacer {
      border-right: 1px solid #d8d8d8;
    }
  }
}

.prefix {
  margin-right: 4px;
}

.row {
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  flex-direction: row;
  margin-bottom: 16px;
  margin-top: 16px;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  &.filled {
    background-color: #e1eef4;
    border: 1px solid #2989af;
    border-radius: 4px;
    padding: 16px;
    font-size: 12px;
  }
}

table {
  border-spacing: 0;
  border: 1px solid #dfdfdf;
  border-collapse: collapse;
  border-radius: 4px;
  background-color: #ffffff;
  font-size: 14px;
  text-align: left;
  width: 100%;
  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }

  th,
  td {
    margin: 0;
    padding: 0.25rem;
    padding-left: 0.5rem;
  }

  th {
    padding: 0.5rem;
  }

  tbody {
    tr:first-child {
      border-top: 1px solid v.$secondary-color;
    }
  }
}

.dropdown-container {
  font-size: 12px;
  width: 200px;
  margin-right: 8px;
  .dropdown__control {
    min-height: 31px;
    cursor: pointer;
    font-size: 12px;
    color: #383b41;
    background-color: #ffffff;
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 #d2d4d6;
    border: none;
    outline: none;
  }
  .header__control {
    min-height: 31px;
    cursor: pointer;
    font-size: 12px;
    color: #383b41;
    padding: 3px;
    background-color: #ffffff;
    border: 1px solid #dfe3e9 !important;
    border-radius: 2px;
    border: none;
    outline: none;
    font-weight: normal;
  }
  .dropdown__indicator-separator {
    background-color: transparent;
  }
}

.form-field {
  font-size: 12px;
  margin-right: 16px;
  margin-bottom: 16px;
  width: 200px;
  text-align: left;
  input {
    outline: none;
    border: none;
    box-shadow: 0 2px 4px 0 #d2d4d6;
    border-radius: 2px;
    padding: 8px 16px 8px 16px;
    box-sizing: border-box;
    width: 100%;
    &.guess {
      background-color: #eaecef;
      border-left: 5px solid #2989af;
    }
    &.overridden {
      text-decoration: line-through;
    }
    &.has-suffix {
      padding-right: 30px;
    }
    &.initial {
      color: #a7b2bb;
      font-family: v.$font-family-roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
    }
    &.disabled {
      background-color: transparent;
      color: #666;
      cursor: not-allowed;
    }
  }
  &.disabled {
    .input-wrapper {
      background-color: #f0f0f0;
      cursor: not-allowed;
    }
    .input-suffix {
      color: #666;
    }
  }
  textarea {
    outline: none;
    border: none;
    box-shadow: 0 2px 4px 0 #d2d4d6;
    border-radius: 2px;
    padding: 8px 16px 8px 16px;
    box-sizing: border-box;
    width: 100%;
  }
  .title {
    margin-bottom: 8px;
    font-weight: bold;
    justify-content: space-between;
    display: flex;
    width: 100%;
  }

  .optional {
    color: #a7b2bb;
    text-align: right;
    font-family: v.$font-family-roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  .input-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  .input-suffix {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #666;
    pointer-events: none;
  }
}
.dropdown-list {
  min-width: 150px;
  position: absolute;
  z-index: 1000;
  flex-direction: column;
  border: 1px solid #e5e5e5;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.title {
  margin-bottom: 8px;
  font-weight: bold;
}

.nav {
  padding: 12px 0 12px 4px;
  display: flex;
  flex-direction: column;
  color: v.$primary-font-color;
  .selection-return {
    font-size: 12px;
    cursor: pointer;
    margin-right: auto;
  }
  #nav-row {
    margin-left: 18px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.context-header {
  border-top: 1px solid #a7b2bb;
  border-bottom: 1px solid #a7b2bb;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 18px;
  padding: 16px 24px 12px 24px;
  .context-group {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: center;
    border-left: 1px solid #a7b2bb;
  }
  .title {
    align-items: center;
    display: flex;
    margin-right: 16px;
  }
  .label {
    h3 {
      font-size: 14px;
      color: #212529;
      font-weight: normal;
      margin-top: 2px;
      margin-bottom: 0px;
    }
  }
  .value {
    color: v.$secondary-font-color;
  }
}

.table {
  overflow-x: auto;
}

.pagination {
  text-align: left;
  margin-top: 10px;
  font-size: 14px;
}

.header {
  display: flex;
  margin-top: 8px;
  align-items: center;
  &.input {
    border: 1px solid #dfe3e9 !important;
    border-radius: 2px;
    padding: 8px;
    display: flex;
  }
}

.column-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #a7b2bb;
  border-radius: 2px;
  background-color: #eaecef;
  padding: 4px 12px;
  margin-bottom: 10px;
  &.active {
    background-color: #ffffff;
  }
}

.column-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px 16px 0px 16px;
  &.active {
    background-color: #eaecef;
  }
  .helper {
    text-align: center;
    margin: 16px;
    padding: 8px;
    border: 1px dashed #a7b2bb;
    border-radius: 2px;
  }
}

#clear-filter {
  width: 100px;
}

.edit-box {
  background-color: #ffffff;
  border-radius: 4px;
  overflow: hidden;
  font-size: 14px;
  .header {
    height: 37px;
    background-color: v.$primary-color;
    color: #ffffff;
    font-size: 14px;
    text-align: center;
    align-items: center;
    display: flex;
    padding-left: 16px;
  }
  .content {
    min-height: 500px;
  }
  .footer {
    border-top: 1px solid #d2d4d6;
    color: v.$secondary-font-color;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 12px 12px 12px;
  }

  .button-disabled {
    background: linear-gradient(0deg, #323232 0%, #666666 25.53%, #979797 100%);
    color: #ffffff;
  }
}

.container-show-errors {
  .error {
    input,
    .dropdown__control {
      border: 1px solid #eb516d;
    }
  }
}

.custom-heading {
  color: var(--Navy, #271d5a);
  font-family: v.$font-family-roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  text-decoration: none;
}

.actions {
  display: flex;
  float: right;
  align-items: center;
  gap: 20px;

  .button {
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    &.add,
    &.save {
      font-weight: 700;
    }

    &.no-fill {
      svg {
        color: v.$navy;
      }
    }
  }
}

.modal {
  &.full-page {
    height: 100%;
    width: 100%;
  }
}

.legend {
  width: 455px;
  flex: none;
  padding: 20px;
  overflow: auto;
  .icon {
    height: 3px;
    width: 10px;
    border-radius: 4px;
  }
}

.row-sm {
  display: flex;
  gap: 5px;
  flex-direction: row;
  align-items: center;
}

.series1 {
  background-color: #3d2f90;
}
.series2 {
  background-color: #19d3c5;
}
.series3 {
  background-color: #fff36e;
}
.series4 {
  background-color: #2a1f4a;
}
.series5 {
  background-color: #ff8a00;
}
