.energy-chart {
  margin-top: auto;
  margin-bottom: auto;
  flex: 1 0 500px;
  min-width: 0;
  height: 600px;
  position: relative;
}
.energy-chart__chart {
  opacity: 100%;
}

.energy-chart__chart--opacity-low {
  opacity: 40%;
}
