.event-details {
  flex-direction: column;
}

.event-details__navigation {
  justify-content: center;
  border-bottom: 1px solid #eaecef;
}
.event-details__main {
  flex-grow: 1;
  min-height: 150px;
}

.event-energy__energy-view {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  flex-wrap: wrap;
}
