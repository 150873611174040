@use '../variables' as v;

.confirm-delete-modal {
  position: fixed;
  width: 500px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  color: v.$primary-font-color;
  z-index: 1040;
  top: 50%;
  left: 50%;
  background-color: #ffffff;
  transform: translate(-50%, -50%);
  border-radius: 2px;
  .header {
    display: flex;
    padding: 15px 20px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid v.$light-grey;
  }
  .content {
    display: flex;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    align-self: stretch;

    div > p {
      color: v.$navy;
      font-feature-settings: 'calt' off;
      font-family: v.$font-family-roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.14px;
    }

    .name {
      font-weight: 700;
    }

    .no-margin {
      margin: 0;
    }
  }
  .footer {
    padding: 16px 16px 16px 16px;
    border-top: 1px solid #d2d4d6;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 12px;
  }
}
