@use '../../variables' as v;

.event-table {
  min-width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  th,
  td {
    padding: 0.75rem 1rem;
    text-align: left;
    color: #3d2f90;
  }

  th {
    border-bottom: 1px solid #1bd3c5;
    font-weight: 600;
  }
}

.status-badge {
  display: inline-block;
  padding: 5px 15px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  border-radius: 100px;
  text-transform: uppercase;

  &.status-happening-now {
    background-color: #f2fcf4;
    color: #6ecd82;
    border: 1px solid #6ecd82;
  }

  &.status-scheduled {
    background-color: #eafffe;
    color: #96e9e4;
    border: 1px solid #96e9e4;
  }

  &.status-canceled {
    background-color: #faecec;
    color: #ce5a5a;
    border: 1px solid #ce5a5a;
  }

  &.status-completed {
    background-color: #f1effe;
    color: #3d2f90;
    border: 1px solid #3d2f90;
  }
}

button {
  cursor: pointer;
  &.icon {
    background: none;
    border: none;
    padding: 0.25rem;
  }

  &.delete {
    color: #ce5a5a;
    &:hover {
      color: #dc2626;
    }
  }

  &.edit {
    color: v.$primary-color;
  }

  &.detail {
    color: #3d2f90;
    opacity: 0.8;
    &.disabled {
      opacity: 0.5;
    }
    &:hover {
      opacity: 1;
    }
  }
}

.events-dropdowns {
  width: 377px;
  margin-bottom: 4px;
  .dropdown-container {
    width: 377px;
    .dropdown__menu > div > div {
      max-height: 200px !important;
    }
  }
}

.building-dropdown {
  margin-left: 40px;
}

.building-modal {
  width: 407px;
  .wrapper-content {
    margin: 20px;
    .form-field {
      font-size: 16px !important;
      margin: 20px 0;
      width: 100%;
      textarea {
        border: 1px solid #cad1d6;
      }
    }
    .title {
      font-weight: bold;
    }
  }
}

.form-information {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 20px 0;
}
