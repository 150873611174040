.overlay-loader {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.overlay-loader__content {
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  font-size: larger;
}
